<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <div class="card">
          <div></div>
          <div class="col-12 mt-2">
            <div class="card-header cardHeader-bg" style="padding: 14px;">
              <router-link to="/discount">
                <button class="btn btn-info rounded-pill mb-2">
                  Back
                </button>
              </router-link>
              <h4 class="card-title mb-0">{{ headerTitle }}</h4>
              <br />
              <div
                v-if="alert"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                class="alert alert-danger"
              >
                {{ alertText }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <b-form-group
                id="input-group-2"
                label="Offer Name *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="name"
                  type="text"
                  required
                  placeholder="Enter offer name"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Restaurant *"
                label-for="input-3"
              >
                <multiselect
                  track-by="id"
                  :custom-label="customLabel"
                  label="username"
                  v-model="selected"
                  :options="users"
                  placeholder="Select Restaurant"
                  @select="onSelect"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.name }} - ({{
                        option.current_address
                      }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Offer Type *"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  @change="offerTypeChange()"
                  v-model="offeredType"
                  :options="offeredTypes"
                ></b-form-select>
              </b-form-group>

              <br /><br />
            </div>
          </div>
        </div>
        <br />

        <div class="card">
          <template>
            <MenuItems
              :meunItems="meunItems"
              :offeredType="offeredType"
              @actualPriceMenuItem="actualPriceMenuItem"
              @menuItemImageForSingleBundle="menuItemImageForSingleBundle"
              @deleteData="deleteData"
              :meunItemsTempArray="meunItemsTempArray"
              @showBulkPopupDeliveryAddress="showBulkPopupDeliveryAddress"
            />
          </template>

          <br />
        </div>

        <br />

        <div class="card">
          <div></div>
          <div class="col-12 mt-2">
            <div></div>
          </div>
          <div class="card-body">
            <div>
              <b-form-group
                id="input-group-3"
                label="Discount Type *"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="disCountType"
                  :options="disCountTypes"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Actual Price:"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  v-model="actualPrice"
                  required
                  placeholder="Actual Price"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Discount Offer:"
                label-for="input-2"
              >
                <b-form-input
                  v-model="offeredDiscount"
                  @keypress="onlyForCurrency"
                  @keyup="discountCalculation()"
                  required
                  placeholder="discount offered"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Discount Price:"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  v-model="discountPrice"
                  type="number"
                  required
                  placeholder="Discount Price"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                required
                id="input-group-2"
                label="Start Date *"
                label-for="input-2"
              >
                <b-form-datepicker
                  :min="new Date().toISOString().substr(0, 10)"
                  v-model="startDate"
                  class="mb-2"
                ></b-form-datepicker>
              </b-form-group>

              <b-form-group
                required
                id="input-group-2"
                label="End Date *"
                label-for="input-2"
              >
                <b-form-datepicker
                  :min="new Date().toISOString().substr(0, 10)"
                  v-model="endDate"
                  class="mb-2"
                ></b-form-datepicker>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Short Description:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="short_description"
                  placeholder="Enter Short Description here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Long Description:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="long_description"
                  placeholder="Enter Long Description here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="CoverImage *"
                label-for="input-2"
              >
                <input type="file" @change="coverImageShow" /><br /><br />
                <img
                  style="width: 140px;
    height: 140px;
    border-radius: 16px;"
                  v-if="coverimageUrl"
                  :src="coverimageUrl"
                />
              </b-form-group>

              <br /><br />
            </div>
          </div>
        </div>
        <br />

        <div class="card">
          <input
            style="padding:10px"
            type="file"
            ref="file"
            multiple="multiple"
            @change="submitFiles"
          />
          <b-container fluid class="p-4 bg-dark" v-if="imagesShow">
            <b-row>
              <b-col-4
                v-for="(image, key) in imagesArray"
                :key="key"
                class="p-3"
              >
                <button
                  style="color:white"
                  class="close ml-1"
                  @click.prevent="removeImage(image)"
                >
                  &times;
                </button>
                <b-img
                  style="height: 150px;max-width: 165px;"
                  thumbnail
                  fluid
                  :src="getLogo(image)"
                  alt="Image 1"
                ></b-img>
              </b-col-4>
            </b-row> </b-container
          ><br />

          <template>
            <ImagesViewTable @deleteData="deleteData" :loans="loans" />
          </template>

          <div class="col-sm-2 col-md-2">
            <b-button class="btn-info" ref="save" type="submit">Save</b-button>
          </div>
          <br />
        </div>
        <br /><br />
        <template v-if="isShowModel">
          <VerficationModal
            :show="showModal"
            :userObj="userObj"
            :actionType="actionType"
            @deleteImageRecord="deleteImageRecord"
            @deleteMeunItem="deleteMeunItem"
            @closeModal="closeModal"
          />
        </template>

        <br /><br />
        <br />
      </b-form>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
// import { appendRow, deleteRow } from "@/Helper/helpers.js";
import Vue from "vue";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import VerficationModal from "./VerficationModal";
import MenuItems from "@/view/pages/Discount/MenuItems";
import ImagesViewTable from "@/view/pages/Discount/ImagesViewTable";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
import Multiselect from "vue-multiselect";
import axios from "axios";
export default {
  components: {
    Multiselect,
    VerficationModal,
    ImagesViewTable,
    // VueGoogleAutocomplete,
    MenuItems,
  },
  data() {
    return {
      deliveryAddresses: [],
      meunItemsTempArray: [],
      deliveryAddress: null,
      minimunOrderAmout: null,
      deliveryCost: null,
      offeredDiscount: 0.0,
      customers: [],
      delivery_time: "",
      short_description: "",
      long_description: "",
      startDate: "",
      endDate: "",
      headerTitle: "Add Discount Item",
      meunItems: [],
      totalMenuItemsPrice: 0.0,
      meunItemsIds: [],
      actualPrice: 0.0,
      discountPrice: 0.0,
      changeClasss: false,
      currentAddressname: "",
      name: "",
      size: null,
      id: "",
      selected: null,
      user: null,
      users: [],
      imagesShow: false,
      showDeleteModal: false,
      showModal: false,
      imagesArray: [],
      loans: [],
      isShowModel: false,
      alertText: "",
      alert: false,
      coverimageUrl: null,
      coverImage: null,
      showImagesViewTable: false,
      deliveryAddressView: false,
      price: "",
      show: true,
      apiUrl: "/user/discount-add",
      disCountType: null,
      disCountTypes: [
        { text: "Select Discount Type", value: null },
        "fixed",
        "percentage",
      ],

      offeredType: null,
      offeredTypes: [
        { text: "Select Offer Type", value: null },
        { text: "Single", value: "single" },
        { text: "Bundle", value: "bundle" },
      ],
    };
  },

  mounted() {
    document.title = "Add Discount";
    this.getAllRestaurant();
    this.getAllCustomers();
    if (this.$router.history.current.path == "/editdiscount") {
      this.headerTitle = "Edit Discount";
      document.title = "Edit Discount";
      this.editDataApppend();
    }
  },
  methods: {
    onlyForCurrency($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.offeredDiscount.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        this.offeredDiscount != null &&
        this.offeredDiscount.indexOf(".") > -1 &&
        this.offeredDiscount.split(".")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },
    offerTypeChange() {
      if (this.offeredType == "bundle") {
        this.loans = [];
      }
    },
    discountCalculation() {
      var covertFloat = parseFloat(this.actualPrice);
      if (covertFloat > 0 && this.offeredDiscount < covertFloat) {
        if (this.disCountType == "percentage") {
          let Discount = parseFloat(
            ((this.offeredDiscount / 100) * this.actualPrice).toFixed(2)
          );
          this.discountPrice = parseFloat(this.actualPrice - Discount).toFixed(
            2
          );
        } else {
          this.discountPrice = parseFloat(
            this.actualPrice - this.offeredDiscount
          ).toFixed(2);
        }
      } else {
        this.toasterMessageFalse(
          "Discounted price cannot be grater then actual price"
        );
      }
    },
    onChangeType() {
      if (this.type == "0") {
        this.showInputForDelivery = false;
      } else {
        this.showInputForDelivery = true;
      }
    },

    submitFiles() {
      Swal.fire({
        title: "Processing...",
        text: "Please wait while your request is being processed",
        buttons: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        closeOnClickOutside: false,
        allowEscapeKey: false,
      });

      let formData = new FormData();

      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        formData.append("images[" + i + "]", file);
      }

      ApiService.post(this.$path + "/save-multiple-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (response.data.code == 200) {
            response.data.data.map((x) => {
              this.imagesArray.push(x);
            });
            this.imagesShow = true;
            Swal.close();
          }
        })
        .catch(function(response) {
          if (response) {
            alert("Technical Issue");
          }
        });
    },

    coverImageShow(e) {
      const file = e.target.files[0];
      this.coverImage = file;
      this.coverimageUrl = URL.createObjectURL(file);
    },

    menuItemImageForSingleBundle(imagesArray) {
      if (this.offeredType == "single") {
        this.loans = imagesArray;
        this.imagesArray = imagesArray;
      } else {
        this.loans = [];
      }
    },

    onSelect(option) {
      this.totalPrice = 0.0;
      this.meunItemsIds = [];
      this.meunItemsTempArray = [];
      this.deliveryAddress = null;
      this.meunItems = option.meunItems;
      this.deliveryAddresses = option.addresses;
      this.delivery_time = option.average_delivery_time;
    },

    onSelectDeliveryAddress(option) {
      this.minimunOrderAmout = option.minimum_order_amount;
      this.deliveryCost = option.delivery_cost;
      const totalMenuItemsPrice = this.meunItemsTempArray.reduce(
        (sum, equity) => {
          return sum + equity.total_price;
        },
        0
      );
      if (parseFloat(option.delivery_cost) > 0) {
        let grandTotal =
          parseFloat(totalMenuItemsPrice) + parseFloat(option.delivery_cost);
        this.totalPrice = parseFloat(grandTotal).toFixed(2);
      }
    },
    onDeliveryAddressRemove(option) {
      this.minimunOrderAmout = option.minimum_order_amount;
      if (parseFloat(option.delivery_cost) > 0) {
        let grandTotal =
          parseFloat(this.totalPrice) - parseFloat(option.delivery_cost);
        this.totalPrice = parseFloat(grandTotal).toFixed(2);
      }
    },

    editDataApppend() {
      this.editdiscount = JSON.parse(localStorage.getItem("editdiscount"));
      if (this.editdiscount.coverImage) {
        this.coverimageUrl = this.editdiscount.coverImage;
        this.coverImage = this.editdiscount.coverImage;
      }
      this.id = this.editdiscount.id;
      this.name = this.editdiscount.name;
      this.selected = this.editdiscount.restaurant;
      this.meunItems = this.editdiscount.meunItem;
      this.actualPrice = this.editdiscount.actual_price;
      this.offeredDiscount = this.editdiscount.offered_discout;
      this.offeredType = this.editdiscount.offered_type;
      this.startDate = this.editdiscount.start_date;
      this.endDate = this.editdiscount.end_date;
      this.short_description = this.editdiscount.short_description;
      this.long_description = this.editdiscount.long_description;
      this.disCountType = this.editdiscount.discount_type;
      this.discountPrice = this.editdiscount.discount_price;
      this.meunItemsTempArray = this.editdiscount.discountItems;
      this.loans = this.editdiscount.images;
    },

    deleteData(object, actioType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actioType;
    },

    toasterMessageFalse(message) {
      Vue.$toast.error(message, {
        position: "top-right",
        duration: 5000,
      });
    },

    toasterMessage() {
      Vue.$toast.success("Delivery Address Save Please Check Blow", {
        position: "top-right",
        duration: 5000,
      });
    },

    closeModal(value) {
      this.isShowModel = value;
    },

    deleteImageRecord(object) {
      let index = this.loans.findIndex((x) => x.id === object.item.id);
      if (index !== -1) {
        this.editRestaurant.images.splice(index, 1);
        this.loans = this.editRestaurant.images;
        localStorage.removeItem("editRestaurant");
        localStorage.setItem(
          "editRestaurant",
          JSON.stringify(this.editRestaurant)
        );
      }

      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    showBulkPopupDeliveryAddress(object, actionType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actionType;
    },

    deleteMeunItem(object) {
      if (this.offeredType == "single") {
        this.loans = [];
      }
      let index = this.meunItemsTempArray.findIndex(
        (x) => x.menu_id === object.item.menu_id
      );
      this.meunItemsTempArray.splice(index, 1);
      let grandTotal =
        parseFloat(this.actualPrice) - parseFloat(object.item.actual_price);
      this.actualPrice = parseFloat(grandTotal).toFixed(2);
      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },
    CurrentAddress: function(addressData) {
      let data = {
        postcode: addressData.postal_code,
        street: addressData.route,
        city: addressData.locality,
        state: addressData.administrative_area_level_1,
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      this.current_address = data;
    },
    customLabel({ name, current_address }) {
      return `${name}—(${current_address})`;
    },

    actualPriceMenuItem(price) {
      let grandTotal = parseFloat(this.actualPrice) + parseFloat(price);
      this.actualPrice = parseFloat(grandTotal).toFixed(2);
    },

    customLabelDeliveryAddress({ street, city, state }) {
      return `(${street} ${city} ${state})`;
    },

    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },
    onSubmit(evt) {
      try {
        if (this.selected == null) {
          this.validationMessage("Please select restaurant");
          evt.preventDefault();
          return false;
        }
        if (this.offeredType == null) {
          this.validationMessage("Please select offered type");
          evt.preventDefault();
          return false;
        }

        if (this.meunItemsTempArray.length == 0) {
          this.validationMessage("Please select Discount item");
          evt.preventDefault();
          return false;
        }

        if (this.disCountType == null) {
          this.validationMessage("Please select discount type");
          evt.preventDefault();
          return false;
        }

        if (this.discountPrice < 0) {
          this.validationMessage(
            "Discount price can not be less then or equal to zero"
          );
          evt.preventDefault();
          return false;
        }
        if (this.startDate == "") {
          this.validationMessage("Please select start date");
          evt.preventDefault();
          return false;
        }

        if (this.endDate == "") {
          this.validationMessage("Please select end date");
          evt.preventDefault();
          return false;
        }

        if (this.coverImage == null) {
          this.validationMessage("Please select cover image");
          evt.preventDefault();
          return false;
        }

        const formData = new FormData();
        evt.preventDefault();
        formData.append("id", this.id);
        this.addLoaderClasses("save");
        formData.append("restaurant_id", this.selected.id);
        formData.append("discount_type", this.disCountType);
        formData.append("offered_type", this.offeredType);
        formData.append("discount_price", this.discountPrice);
        formData.append("start_date", this.startDate);
        formData.append("end_date", this.endDate);
        formData.append("short_description", this.short_description);
        formData.append("long_description", this.long_description);
        formData.append("delivery_time", this.deliveryTime);
        formData.append("actual_price", this.actualPrice);
        formData.append("name", this.name);
        formData.append("offered_discout", this.offeredDiscount);
        formData.append(
          "menu_item_data",
          JSON.stringify(this.meunItemsTempArray)
        );
        formData.append("discout_item_image", JSON.stringify(this.imagesArray));
        if (this.coverImage) {
          formData.append("coverImage", this.coverImage);
        }

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + this.apiUrl, formData, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              let message = "";
              if (this.id != "") {
                message = "Record has been updated successfully";
              } else {
                message = "Record has been added successfully";
              }
              localStorage.setItem("alertmessage", JSON.stringify(message));
              this.$router.push({ name: "discount" });
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
              this.removeLoaderClasses("save");
            }

            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    removeLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    profileImageShow(e) {
      const file = e.target.files[0];
      this.profileImage = file;
      this.profileimageUrl = URL.createObjectURL(file);
    },

    getAllRestaurant() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user/restaurant/list", "", {
            headers,
          })
          .then((response) => {
            this.users = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getAllCustomers() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user/user-dropdown", "", {
            headers,
          })
          .then((response) => {
            this.customers = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
.btn-info {
  background-color: #5cb700;
  border-color: #5cb700;
}
.btn-info:hover {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:active {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:focus {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}
</style>
